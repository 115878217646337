<template>
  <section>
    <hero-global img="/img/pmr/pmr_1.svg"
                 first-width="75"
                 last-width="25"
                 title="Servicio Asistencia PMR"/>
  </section>
  <br/>
  <section class="container p-2-rem">
    <div>
      <h2 class="subtitle text-center">Servicio de asistencia a Personas con Movilidad Reducida en los Aeropuestos de
        Aena Aeropuertos</h2>
    </div>
    <br/>
    <br/>
    <div class="grid-2">
      <div class="grid">
        <article>
          <p>En cumplimiento del Reglamento (CE) 1107/2006 del Parlamento Europeo, Aena Aeropuertos presta en todos los
            aeropuertos de su red un servicio de atención a los pasajeros con movilidad reducida (PMR).
          </p>
          <br/>
          <p>
            Un pasajero con movilidad reducida es aquél cuya movilidad está limitada a efectos de la utilización de un
            medio
            de transporte debido a cualquier discapacidad física (sensorial o de locomoción, permanente o temporal) o
            mental, a su edad o a cualquier otra causa de discapacidad que necesite una atención especial y la
            adaptación a
            sus necesidades de los servicios que se ponen a disposición de todos los pasajeros.</p>
          <p>
            De manera genérica, <b>el servicio</b> o asistencia a recibir por parte del pasajero con movilidad reducida
            <b>consiste en</b>:</p>
          <ul class="font-family-roboto p-1-rem">
            <li><b>En el aeropuerto de salida:</b> recogida en el punto de encuentro, ayuda con su equipaje de mano,
              acompañamiento al mostrador de facturación, asistencia en los trámites necesarios para facturar,
              acompañamiento hacia la zona de embarque, asistencia los controles de seguridad necesarios, embarque en el
              avión y desplazamiento hasta el asiento designado en el mismo.
            </li>
            <li><b>En el aeropuerto de llegada:</b> ayuda con el equipaje de mano, desplazamiento desde el asiento hasta
              la
              puerta del avión, desembarque, desplazamiento hacia la sala de equipajes, asistencia en los controles de
              seguridad necesarios y desplazamiento hasta un punto de encuentro.
            </li>
            <li><b>En los tránsitos/conexiones:</b> asistencia necesaria para realizar con éxito el tránsito o la
              conexión,
              incluyendo, si procede, maniobras de embarque, desembarque, traslados por la terminal, etc.
            </li>
          </ul>
        </article>
      </div>
      <div class="grid">
        <img class="w-100 d-block" src="/img/pmr/pmr_2.png" alt="Imagen PMR" title="Imagen PMR">
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <article class="p-x-2-rem">
      <h2 class="subtitle">Consideraciones a tener en cuenta a la hora de viajar en avión:</h2>
      <ol class="p-2-rem">
        <li>
          <article>
            <h3 class="font-family-roboto">Reserva y solicitud de asistencia:</h3>
            <br/>
            <p>En el momento de realizar la reserva de sus billetes usted debe informar de sus limitaciones así como de
              la necesidad de recibir asistencia en el aeropuerto. Sea realista sobre sus necesidades, especialmente si
              no puede caminar largas distancias sin ayuda.
            </p>
            <br/>
            <p>
              Sólo si la petición de asistencia se realiza con antelación ésta puede garantizarse a su llegada al
              aeropuerto conforme a los estándares de calidad. Le aconsejamos que realice su reserva como mínimo 48
              horas antes de iniciar el viaje. De esta manera podrá asegurar que la asistencia que precisa estará
              disponible.</p>
            <br/>
            <p>
              De acuerdo con el Reglamento 1107/2006, las compañías aéreas, sus agentes o los operadores turísticos
              podrán negarse a aceptar una reserva o denegar el embarque a una PMR ante las siguientes circunstancias:
            </p>
            <ul class="font-family-roboto p-1-rem">
              <li>Por razones de seguridad establecidos mediante legislación internacional, comunitaria o nacional.</li>
              <li>Cuando las dimensiones de la aeronave o sus puertas imposibilitan físicamente el embarque o transporte
                de la persona con discapacidad o movilidad reducida.
              </li>
            </ul>
            <br/>
            <p>
              <b>La solicitud de asistencia</b>, así como la notificación de sus necesidades puede efectuarse a través
              de
              diferentes canales:
            </p>
            <br/>
            <p>
              A través de los canales de reserva disponibles de su agente de viajes o compañía aérea en el momento de
              realizar la reserva o adquirir el billete.</p>
            <br/>
            <p>
              Adicionalmente, y de manera opcional, para la asistencia en los aeropuertos españoles de la red de Aena
              Aeropuertos, podrá realizar la solicitud de asistencia a través de la página web de Aena (www.aena.es) o a
              través del teléfono del Servicio de Información y Atención Telefónica (902 404 704 / (+34) 913 211 000) de
              Aena Aeropuertos.</p>
          </article>
        </li>
        <li>
          <article>
            <h3 class="font-family-roboto">Asistencia:</h3>
            <br/>
            <ul class="font-family-roboto p-x-1-rem">
              <li>Llegada al aeropuerto:</li>
            </ul>
            <br/>
            <p>Tanto en los aeropuertos de Aena Aeropuertos como en el resto de aeropuertos europeos existen puntos de
              encuentro debidamente señalizados en los que las personas con movilidad reducida pueden anunciar su
              llegada al aeropuerto y solicitar asistencia.</p>
            <br/>
            <p>Para asegurar un servicio de asistencia adecuado y de calidad en el momento de su llegada al aeropuerto,
              debe tener en cuenta lo siguiente:</p>
            <p>
              En caso de haber tramitado la solicitud únicamente a través de la compañía aérea o agente de viajes, debe
              dirigirse a un punto de encuentro y avisar de su llegada a la hora de cita fijada. Si no se ha fijado
              ninguna hora, debe presentarse en un punto de encuentro y avisar de su llegada al menos dos horas antes de
              la hora de salida programada de su vuelo. </p>
            <br/>
            <p>En caso de haber gestionado la solicitud de asistencia a través de los canales dispuestos por Aena
              Aeropuertos, adicionalmente a la comunicación efectuada a la compañía aérea, debe presentarse en el punto
              de encuentro concertado con Aena Aeropuertos a la hora de cita fijada en su solicitud y avisar de su
              llegada. Si el tiempo de antelación de la solicitud de asistencia de Aena Aeropuertos es menor que el
              requerido por la compañía aérea, deberá respetar este último, por ser más restrictivo.</p>
            <br/>
            <p>Una persona del servicio de asistencia le recogerá y le asistirá desde ese mismo punto de encuentro hasta
              que usted esté sentado en su asiento del avión. En caso de que sea necesario, le proporcionará una silla
              de ruedas.</p>
            <br/>
            <ul class="font-family-roboto p-x-1-rem">
              <li>Controles de seguridad:</li>
            </ul>
            <br/>
            <p>De acuerdo con la normativa vigente en materia de seguridad en la aviación civil, las personas con
              discapacidad y/o movilidad reducida deberán pasar los mismos controles de seguridad que el resto de los
              pasajeros, realizándose los registros en la medida en que su discapacidad lo permita.</p>
            <br/>
            <ul class="font-family-roboto p-x-1-rem">
              <li>A bordo:</li>
            </ul>
            <br/>
            <p>En el proceso de embarque de la aeronave, si utiliza una silla de ruedas plegable, ésta podrá ser
              almacenada en la cabina de los pasajeros si hay espacio para ello. Si la silla dispone de batería, deberá
              ir siempre en la bodega de la aeronave por motivos de seguridad en vuelo. Si necesita una silla de ruedas
              a bordo de la aeronave, deberá solicitarla al hacer la reserva.</p>
          </article>
        </li>
        <li>
          <article>
            <h3 class="font-family-roboto">Equipaje:</h3>
            <br/>
            <p>
              Según los convenios internacionales, la indemnización por pérdidas o daños a su equipaje personal
              (incluida
              la silla de ruedas), se calculará sobre la base del peso del objeto/s, no de su valor, salvo que se haga
              una
              declaración especial, a más tardar en el momento de facturar, y abone una tarifa suplementaria. Antes de
              viajar deberá asegurarse de que su seguro de viaje cubre su equipo de movilidad. Es posible que necesite
              una
              cobertura adicional. Consulte con su compañía aérea.</p>
          </article>
        </li>
        <li>
          <article>
            <h3 class="font-family-roboto">Reclamaciones:</h3>
            <br/>
            <p>Si considera que no ha recibido el trato adecuado durante la asistencia prestada por Aena Aeropuertos en
              el aeropuerto y desea poner una reclamación o hacer cualquier sugerencia sobre la misma, puede dirigirse a
              la dirección sinbarreras@aena.es o hacer uso de las Hojas de Quejas/Reclamaciones de Aena Aeropuertos, que
              encontrará en los mostradores de información de los aeropuertos.</p>
            <br/>
            <p>Si considera que se le ha negado la reserva o se le ha denegado el embarque injustificadamente, escriba
              una carta de reclamación a la compañía aérea, a su agente o al correspondiente operador aéreo o utilice
              las hojas de reclamaciones que éstas tienen a su disposición.</p>
            <br/>
            <p>Si no recibiera contestación o no obtuviera satisfacción a su reclamación, podrá presentar la queja
              correspondiente ante la Dirección General de Aviación Civil.</p>
            <br/>
            <p>Si desea mayor información acerca del servicio de asistencia a personas con movilidad reducida puede
              dirigirse a: </p>
            <br/>
            <p>La Web de Aena Aeropuertos:
              <a class="color-primary"
                 href="http://www.aena-aeropuertos.es/csee/Satellite/aeropuertos/es/Page/1213017924001/"
                 target="_blank">http://www.aena-aeropuertos.es/csee/Satellite/aeropuertos/es/Page/1213017924001/</a>
            </p>
            <br/>
            <p>Los Teléfonos del Servicio de Información y Atención Telefónica de Aena Aeropuertos: 902 404 704, (+34)
              913 211 000. </p>
          </article>
        </li>
      </ol>
    </article>
  </section>
  <section class="container p-2-rem">
    <other-links-interest class="links-interest-box container"/>
  </section>
  <br/>
  <br/>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";

export default {
  name: "PMR",
  components: {OtherLinksInterest, HeroGlobal}
}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

ol {
  & > li::marker {
    color       : $blueLight;
    font-size   : 64px;
    font-weight : bold;
    content     : counter(list-item) "  ";
  }
}

ul {
  list-style: disc;
}
</style>
